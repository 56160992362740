import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { jwtDecode } from 'jwt-decode';
import { authConstants } from '../constants/auth.constants';
import { JwtCustomPayload } from '../../../common/models/jwt-payload';

@Injectable({
    providedIn: 'root',
})
export class PartnerProgramAuthServiceService {
    jwtHelper = new JwtHelperService();

    public getToken(): string {
        return JSON.parse(JSON.stringify(localStorage.getItem('partnerProgramToken') ?? ''));
    }

    public getUserId(): string {
        const token: string = this.getToken();
        const tokenPayload = jwtDecode(token);

        return tokenPayload?.['sub'] ?? '';
    }
    public getPartnerProgramUserId(): string {
        return this.getUserId();
    }

    public getUserName(): string {
        const tokenPayload = this.decodeJwtPayload();

        return `${tokenPayload.firstName} ${tokenPayload.lastName}`;
    }

    public getFirstName(): string {
        const tokenPayload = this.decodeJwtPayload();

        return `${tokenPayload.firstName}`;
    }

    public getLastName(): string {
        const tokenPayload = this.decodeJwtPayload();

        return `${tokenPayload.lastName}`;
    }

    public getEmail(): string {
        const tokenPayload = this.decodeJwtPayload();

        return `${tokenPayload.sub}`;
    }

    public isAuthenticated(): boolean {
        const token = this.getToken();
        // Check whether the token is expired and return
        // true or false
        return !this.jwtHelper.isTokenExpired(token);
    }

    public isPartnerProgramAuthenticated(): boolean {
        return this.isAuthenticated();
    }

    public isLoggedIn(): boolean {
        if (this.isAuthenticated()) {
            const tokenPayload = this.decodeJwtPayload();

            return tokenPayload?.roles.filter((role) => role.name === authConstants.ROLE_USER).length > 0;
        }

        return false;
    }

    public logout(): void {
        localStorage.clear();
        sessionStorage.clear();
    }

    public decodeJwtPayload(): JwtCustomPayload {
        const token: string = this.getToken();

        return jwtDecode<JwtCustomPayload>(token);
    }
}
